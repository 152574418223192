<template>
  <CyMenu
    v-model="isMenuOpen"
    :close-on-content-click="false"
    offset-y>
    <template #activator="{ on, attrs }">
      <div>
        <CyButton
          icon="add"
          variant="tertiary"
          theme="grey"
          v-bind="attrs"
          v-on="on">
          {{ $t('addFilter') }}
        </CyButton>
      </div>
    </template>

    <div
      class="filter-tree-menu"
      data-cy="filter-tree-menu">
      <header
        v-if="!isTopLevel"
        data-cy="category-header">
        <v-list-item
          dense
          data-cy="back-button"
          aria-label="Back"
          @click="backBtnHandler">
          <v-list-item-icon class="mr-2">
            <v-icon>chevron_left</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="font-weight-bold">
              {{ levelDepth === 1 && $te(focusedCategory) ? $t(focusedCategory) : focusedCategory }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="mx-0 my-2"/>
      </header>

      <v-list
        class="filter-list"
        dense
        data-cy="filter-list">
        <v-text-field
          v-model="search"
          :placeholder="`${$t(searchBarLabel)}...`"
          data-cy="search-bar"
          class="search-bar pa-2 pt-0"
          single-line
          clearable
          clear-icon="cancel"
          autofocus
          persistent-placeholder
          hide-details
          filled>
          <template #prepend-inner>
            <v-icon>
              search
            </v-icon>
          </template>
        </v-text-field>

        <v-list-item
          v-for="[key, itemValue] in focusedSubTreeWithSearch"
          :key="key"
          :aria-label="getItemLabel(key, itemValue)"
          class="filter-list__item"
          @click="itemClickHandler(key)">
          <v-list-item-action v-if="isInFilterDepth">
            <v-checkbox
              color="secondary"
              :input-value="getCheckboxValue(key)"
              :aria-label="getItemLabel(key, itemValue)"/>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ getItemLabel(key, itemValue) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <template v-if="!isInFilterDepth && $slots['footer'] && !_.isEmpty(focusedSubTreeWithSearch)">
          <v-divider class="mx-0 my-2"/>
          <slot name="footer"/>
        </template>

        <v-list-item
          v-if="_.isEmpty(focusedSubTreeWithSearch)"
          class="black--text"
          disabled>
          {{ $t('noItemsFound') }}
        </v-list-item>
      </v-list>

      <template v-if="isInFilterDepth">
        <v-divider class="mx-0 my-2"/>
        <footer
          data-cy="actions-footer"
          :class="['d-flex px-2', anyFiltersSelectedInCurrentCategory ? 'justify-space-between' : 'justify-end']">
          <CyButton
            v-if="anyFiltersSelectedInCurrentCategory"
            variant="tertiary"
            theme="grey"
            @click="clearCategoryFilters">
            {{ $t('clearFilters') }}
          </CyButton>
          <CyButton
            data-cy="apply-button"
            @click="applyFilters">
            {{ $t('applyFilters') }}
          </CyButton>
        </footer>
      </template>
    </div>
  </CyMenu>
</template>

<script>
const defaultFilterTreeFormatter = (filterTree) => {
  const filterTreeNoEmpty = _.omitBy(filterTree, _.isEmpty)
  return _.mapValues(filterTreeNoEmpty, (valueArray) => _.fromPairs(_.map(valueArray, (value) => [value, null])))
}

export default {
  name: 'CyFilterTree',
  props: {
    filterTree: {
      type: Object,
      default: () => {},
    },
    value: {
      type: Array,
      default: () => [],
    },
    filterTreeFormatter: {
      type: Function,
      default: defaultFilterTreeFormatter,
    },
  },
  data: () => ({
    focusedTreePath: '',
    selectedFilters: [],
    search: '',
    isMenuOpen: false,
  }),
  computed: {
    formattedFilterTree () {
      return this.filterTreeFormatter(this.filterTree)
    },
    isTopLevel () {
      return !this.focusedTreePath
    },
    focusedSubTree () {
      if (this.isTopLevel) return this.formattedFilterTree
      return _.get(this.formattedFilterTree, this.focusedTreePath, {})
    },
    focusedCategory () {
      if (this.isTopLevel) return null
      return _.last(this.focusedTreePath.split('.'))
    },
    levelDepth () {
      const pathArray = this.isTopLevel ? [] : this.focusedTreePath.split('.')
      return pathArray.length
    },
    isInFilterDepth () {
      return _.some(_.values(this.focusedSubTree), (item) => item === null || typeof item === 'number')
    },
    anyFiltersSelectedInCurrentCategory () {
      return this.isInFilterDepth && _.some(this.selectedFilters, (selection) => selection.startsWith(this.focusedTreePath))
    },
    focusedSubTreeWithSearch () {
      if (!this.search) return _.toPairs(this.focusedSubTree)
      return _.toPairs(this.focusedSubTree).filter(([key]) => key.toLowerCase().includes(this.search.toLowerCase()))
    },
    searchBarLabel () {
      if (this.isTopLevel) return 'filterBy'
      if (this.isInFilterDepth) return 'searchForValues'
      return 'forms.fieldSearch'
    },
  },
  watch: {
    value (newValue) {
      this.selectedFilters = newValue
    },
    isMenuOpen (isOpen) {
      if (!isOpen) {
        this.focusedTreePath = ''
        this.search = ''
      }
    },
  },
  mounted () {
    this.selectedFilters = this.value
  },
  methods: {
    focusSubTree (itemKey) {
      this.search = ''
      const pathArray = this.isTopLevel ? [] : this.focusedTreePath.split('.')
      this.focusedTreePath = [...pathArray, itemKey].join('.')
    },
    getItemLabel (key, value) {
      if (this.isTopLevel) return this.$te(key) ? this.$t(key) : key
      if (this.isInFilterDepth) {
        if (value !== null) return `${key} (${value})`
        return `${key}`
      }
      return key
    },
    backBtnHandler () {
      this.search = ''
      const pathArray = this.focusedTreePath.split('.')
      this.focusedTreePath = pathArray.slice(0, -1).join('.')
    },
    toggleFilter (key) {
      const filterKey = `${this.focusedTreePath}.${key}`
      this.selectedFilters = _.xor(this.selectedFilters, [filterKey])
    },
    itemClickHandler (key) {
      this.isInFilterDepth ? this.toggleFilter(key) : this.focusSubTree(key)
    },
    getCheckboxValue (key) {
      const filterKey = `${this.focusedTreePath}.${key}`
      return _.includes(this.selectedFilters, filterKey)
    },
    clearCategoryFilters () {
      this.selectedFilters = this.selectedFilters.filter((filter) => !filter.startsWith(this.focusedTreePath))
    },
    applyFilters () {
      this.$emit('input', this.selectedFilters)
      this.isMenuOpen = false
    },
  },
  i18n: {
    messages: {
      en: {
        addFilter: 'Add filter',
        applyFilters: 'Apply filters',
        author: '@:Author',
        clearFilters: 'Clear filters',
        environments: '@:Environment',
        filterBy: 'Filter by',
        labels: '@:untranslated.label',
        maintainer: '@:Maintainer',
        modules: 'Module',
        noItemsFound: 'No items found',
        projects: '@:Project',
        provider: '@:Provider',
        providers: '@:provider',
        searchForValues: 'Search for values',
        tags: '@:untranslated.tags',
        types: '@:forms.type',
      },
      es: {
        addFilter: 'Añadir filtro',
        applyFilters: 'Aplicar filtros',
        author: '@:Author',
        clearFilters: 'Borrar filtros',
        environments: '@:Environment',
        filterBy: 'Filtrado por',
        labels: '@:untranslated.label',
        maintainer: '@:Maintainer',
        modules: 'Módulo',
        noItemsFound: 'No artículos encontrados',
        projects: '@:Project',
        provider: '@:Provider',
        providers: '@:provider',
        searchForValues: 'Buscar valores',
        tags: '@:untranslated.tags',
        types: '@:forms.type',
      },
      fr: {
        addFilter: 'Ajouter un filtre',
        applyFilters: 'Appliquer les filtres',
        author: '@:Author',
        clearFilters: 'Effacer les filtres',
        environments: '@:Environment',
        filterBy: 'Filtrer par',
        labels: '@:untranslated.label',
        maintainer: '@:Maintainer',
        modules: 'Module',
        noItemsFound: 'Aucun filtre correspondant',
        projects: '@:Project',
        provider: '@:Provider',
        providers: '@:provider',
        searchForValues: 'Rechercher des valeurs',
        tags: '@:untranslated.tags',
        types: '@:forms.type',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.filter-tree-menu {
  width: 264px;
  padding: $spacer-2 0;

  ::v-deep .v-list-item {
    padding: 0 #{$spacer-4};
  }
}

::v-deep .search-bar.v-input {
  font-size: $font-size-default;

  .v-input__control {
    .v-input__slot {
      min-height: 36px;
      max-height: 36px;
      border-radius: 4px;
      background-color: cy-get-color("primary", "light-5");

      &::before {
        border: none;
      }

      &::after {
        display: none;
      }
    }

    .v-input__prepend-inner,
    .v-input__append-inner {
      margin-top: 6px;
      color: cy-get-color("primary", "light-3");

      .v-icon {
        color: cy-get-color("primary", "light-3") !important;
      }
    }

    .v-text-field__slot input::placeholder {
      color: cy-get-color("primary", "light-3");
    }
  }

  &.v-text-field--filled:not(.v-input--is-focused.v-input--has-state) > .v-input__control > .v-input__slot:hover {
    background-color: cy-get-color("primary", "light-5");
  }
}
</style>
