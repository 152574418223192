<template>
  <article>
    <header class="preview-header rounded pb-1 px-6 pt-6">
      <div class="d-flex justify-space-between width-100 mb-2">
        <div class="d-flex align-center">
          <CyStackAvatar
            :size="32"
            :stack="stack"
            class="mr-2"/>
          <h2 class="stack-name">
            {{ stack.name }}
          </h2>
          <CyTooltip
            :max-width="250"
            right>
            <template #activator="{ on }">
              <CyTag
                class="ml-2"
                :variant="$static.visibilityTags[stackVisibility].variant"
                :icon-before="$static.visibilityTags[stackVisibility].icon"
                v-on="on">
                {{ $t(`stacks.visibility.${stackVisibility}`) }}
              </CyTag>
            </template>
            {{ $t(`stacks.visibility.${stackVisibility}${parentOrgText}Hint`) }}
          </CyTooltip>
        </div>
        <div class="d-flex align-center">
          <CyButton
            aria-label="Stack fullscreen"
            class="mr-2"
            icon-only
            theme="primary"
            variant="tertiary"
            icon="fullscreen"
            @click="() => $router.push({ name: 'stack', params: { stackRef: stack.ref } })"/>
          <CyButton
            aria-label="Stack preview close"
            icon-only
            theme="primary"
            variant="tertiary"
            icon="close"
            @click="$emit('close')"/>
        </div>
      </div>
      <div class="mb-2">
        {{ stack.description }}
      </div>
      <div>
        <CyHeaderMetadata
          v-if="!_.isEmpty(stack.team)"
          :to="stackBelongsToCurrentOrg ? { name: 'team', params: { teamCanonical: stack.team.canonical } } : null">
          <span class="d-flex align-center">
            <CyAvatar
              :item="stack.team"
              sm/>
            {{ stack.team.name }}
          </span>
        </CyHeaderMetadata>
        <CyHeaderMetadata
          icon="domain"
          :label="stack.organization_canonical"
          class="pl-0"/>
        <CyHeaderMetadata
          v-if="stack.quota_enabled"
          icon="data_usage"
          :label="$t('quotas.requiresQuota')"/>
      </div>
    </header>
    <v-tabs
      v-model="activeTab"
      :grow="false"
      background-color="transparent"
      class="flex-grow-0 px-2">
      <v-tabs-slider color="secondary"/>
      <v-tab
        v-for="tab in tabs"
        :key="tab.id"
        :ref="`tab-${tab.id}`"
        :href="`#${tab.id}`">
        {{ tab.name }}
      </v-tab>
    </v-tabs>
    <v-divider/>
    <main class="stack__content cy-scrollbars pa-6">
      <v-tabs-items v-model="activeTab">
        <v-tab-item value="readme">
          <vue-showdown
            v-if="!_.isEmpty(readme)"
            class="markdown-body"
            flavor="github"
            :extensions="['showdownHighlight']"
            :options="{ emoji: true, ghCompatibleHeaderId: true, ghCodeBlocks: true, strikethrough: true, ghMentions: true, tables: true, tasklists: true }"
            :markdown="_.unescape(readme.replace(/&#34;/g, '&quot;'))"/>
          <div
            v-else
            class="empty-state">
            <span class="empty-state__content py-8">
              <v-icon
                class="empty-state__icon"
                size="32">menu_book</v-icon>
              <h2 class="h5 mb-2">{{ $t('addReadme.title') }}</h2>
              <p>{{ $t('addReadme.text') }}</p>
              <a
                :href="$docLinks.stacks.readme"
                class="cy-link cy-link--external"
                rel="noopener noreferrer"
                target="_blank">
                {{ $t('addReadme.link') }}
              </a>
            </span>
          </div>
        </v-tab-item>
        <v-tab-item value="details">
          <CyWizardServiceDetailsSidebar
            :stack="stack"
            :show-helper-card="false"
            :catalog-repository="catalogRepository"/>
        </v-tab-item>
      </v-tabs-items>
    </main>
    <footer class="rounded">
      <v-divider/>
      <div class="d-flex justify-end px-6 py-4">
        <CyButton
          theme="primary"
          variant="secondary"
          @click="$emit('close')">
          {{ $t('forms.btnClose') }}
        </CyButton>
        <CyButton
          v-if="showSelectBtn"
          theme="secondary"
          variant="primary"
          icon="check"
          @click="$emit('select')">
          {{ $t('forms.btnSelect') }}
        </CyButton>
      </div>
    </footer>
  </article>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import { CycloidIcons } from '@/assets/icons'
import CyHeaderMetadata from '@/components/header/metadata.vue'
import CyStackAvatar from '@/components/stack-avatar.vue'
import CyWizardServiceDetailsSidebar from '@/components/wizard/service-details-sidebar.vue'
import { VISIBILITIES } from '@/utils/helpers/stacks'

export default {
  name: 'CyStackPreview',
  components: {
    CyStackAvatar,
    CyHeaderMetadata,
    CyWizardServiceDetailsSidebar,
  },
  props: {
    stack: {
      type: Object,
      default: () => ({}),
    },
    showSelectBtn: {
      type: Boolean,
      default: true,
    },
    stackBelongsToCurrentOrg: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      activeTab: 'readme',
    }
  },
  computed: {
    ...mapState('organization/catalogRepository', {
      catalogRepository: (state) => state.detail,
    }),
    $static: () => ({
      visibilityTags: VISIBILITIES,
    }),
    tabs () {
      return [
        {
          id: 'readme',
          name: this.$t('readme'),
        },
        {
          id: 'details',
          name: this.$t('Details'),
        },
      ]
    },
    parentOrgText () {
      if (_.isEqual(this.stackVisibility, 'shared') && !this.stackBelongsToCurrentOrg) {
        return 'ByParentOrg'
      }
      return ''
    },
    isCatalogRepositoryPage () {
      return this.$route.name === 'catalogRepository'
    },
    stackVisibility () {
      return this.stack?.visibility
    },
    canGetCatalogRepository () {
      if (!this.stack.service_catalog_source_canonical) return
      return this.$cycloid.permissions.canDisplay('GetServiceCatalogSource', this.stack.service_catalog_source_canonical)
    },
    readme () {
      const imageLink = this.repoImageLink || this.getStackImageLink()
      const { readme } = this.stack

      return readme && this.stack.visibility.shared
        ? readme.replaceAll(/src="(?!http)+([\w:/.-])+/g, (match) => `src="${imageLink}/${match.replace('src="', '')}`)
        : readme && readme.replaceAll(/src=|(https:\/\/\S+(\.png|\.jpg|\.jpeg|\.gif)|http:\/\/\S+(\.png|\.jpg|\.jpeg|\.gif))/g,
          (match) => this.replacePrivateImages(match))
    },
  },
  created () {
    if (!this.canGetCatalogRepository || this.isCatalogRepositoryPage) return
    this.GET_CATALOG_REPOSITORY({ catalogRepositoryCanonical: this.stack.service_catalog_source_canonical })
  },
  destroyed () {
    if (!this.isCatalogRepositoryPage) this.RESET_CR_STATE()
  },
  methods: {
    ...mapActions('organization/catalogRepository', ['GET_CATALOG_REPOSITORY']),
    ...mapMutations('organization/catalogRepository', [
      'RESET_CR_STATE',
    ]),
    replacePrivateImages (match) {
      return match.includes('src=')
        ? match.replaceAll(match, `width="50" src="${CycloidIcons.inaccessible}"`)
        : match.replaceAll(match, `${CycloidIcons.inaccessible}`)
    },
    getStackImageLink () {
      if (!this.stack.image) return ''

      const regex = /^(?<https>https?):\/\/(?<domain>[\w.-]+)\/(?<org>[\w.-]+)\/(?<repo>[\w.-]+)\/(?<branch>[\w.-]+)\//g
      const { https, domain, org, repo, branch } = regex.exec(this.stack.image)?.groups || {}

      return `${https}://${domain}/${org}/${repo}/${branch}`
    },
  },
  i18n: {
    messages: {
      en: {
        addReadme: {
          title: 'Add a README',
          text: `We couldn't find a README.md file in the source code, add one to showcase the details of this stack.`,
          link: 'Learn more about READMEs',
        },
        private: 'Private',
        public: 'Public',
        readme: 'Readme',
      },
      es: {
        addReadme: {
          title: 'Agregar un README',
          text: `No pudimos encontrar un archivo README.md en el código fuente, agréguelo para mostrar los detalles de esta configuración.`,
          link: 'Obtenga más información sobre los archivos README',
        },
        private: 'Privada',
        public: 'Pública',
        readme: 'Leerme',
      },
      fr: {
        addReadme: {
          title: 'Ajouter un README',
          text: `Nous n'avons pas pu trouver de fichier README.md dans le code source, veuillez en ajouter un pour présenter les détails de cette configuration.`,
          link: 'En savoir plus sur les README',
        },
        private: 'Privée',
        public: 'Publique',
        readme: 'Lisez-moi',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
article {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  background-color: cy-get-color("grey", "light-4");

  .preview-header {
    background-color: cy-get-color("white");

    .stack-name {
      font-size: $font-size-h3;
    }
  }

  .v-tabs {
    background-color: cy-get-color("white");
  }

  .stack__content {
    flex-grow: 1;
    overflow-y: auto;
    background-color: cy-get-color("grey", "light-4");

    .v-tabs-items,
    .markdown-body,
    .sidebar {
      background-color: cy-get-color("grey", "light-4");
    }
  }

  .empty-state {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 512px;
    margin: auto;
    text-align: center;

    &__icon {
      margin-bottom: $spacer-6;
      color: cy-get-color("primary", "light-3");
    }
  }

  footer {
    background-color: cy-get-color("white");
  }

  .cy-avatar {
    border-radius: 4px !important;
  }
}
</style>
