<template>
  <div
    ref="serviceCatalogContainer"
    :class="['service-catalog-container', {
      preview: isOnStacksPage,
    }]"
    data-cy="service-catalog">
    <section class="header">
      <div class="header__search-bar">
        <div class="header__toggle-filter">
          <CyBtnToggle
            v-model="toggleFilter"
            :mandatory="!_.isNil(toggleFilter)"
            :items="visibilityItems"
            active-theme="secondary"
            @input="handleVisibilityChange"/>
        </div>

        <v-text-field
          v-model="search"
          :placeholder="$t('forms.fieldSearch')"
          hide-details
          class="cy-search-field ma-0 py-0"
          aria-label="search-field"
          data-cy="search-field"
          prepend-icon="search"
          clearable
          clear-icon="cancel"/>

        <CyFilterTree
          v-model="selectedFilters"
          :filter-tree="filterTree">
          <template #footer>
            <v-list-item @click="filterByInfraImported">
              {{ $t('stacks.catalogStatus.infraImportName') }}
            </v-list-item>
          </template>
        </CyFilterTree>
      </div>

      <div class="header__actions">
        <CyMenu
          v-if="hideHiddenFilter"
          v-model="isMenuOpen"
          left
          offset-y
          :items="$static.hiddenItem"/>
        <CyButton
          v-if="hasCreateStackBtnVisible"
          v-has-rights-to="'CreateServiceCatalog'"
          class="create-stack__button"
          data-cy="new-stack-button"
          icon="add"
          @click="openStackModal">
          {{ $t('createNewStack') }}
        </CyButton>
      </div>
    </section>

    <section
      v-if="!_.isEmpty(selectedFiltersTags) || importStatus"
      class="tags">
      <div
        v-for="[tagKey, tagValue] in selectedFiltersTags"
        :key="tagValue"
        class="mb-2">
        <CyTag
          icon-after="close"
          variant="default"
          @click-icon-after="handleTagClose(tagKey, tagValue)">
          <b class="mr-2">
            {{ _.capitalize(tagKey) }}:
          </b>
          <span>
            {{ tagValue }}
          </span>
        </CyTag>
      </div>
      <div class="mb-2">
        <CyTag
          v-if="importStatus"
          icon-after="close"
          variant="default"
          @click-icon-after="filterByInfraImported">
          <b class="mr-2">
            {{ $t('InfraImport') }}:
          </b>
          <span>
            {{ importStatus }}
          </span>
        </CyTag>
      </div>
    </section>

    <div
      v-if="loadingConfig"
      class="text-center mt-12">
      <v-progress-circular
        indeterminate
        color="secondary"
        data-cy="progress-bar"/>
      <h4
        v-if="loadingConfig"
        class="grey--text mt-6">
        {{ $t('loadingServiceConfig', { service: loadingStackName }) }}
      </h4>
    </div>

    <div
      v-else-if="loading || firstLoading"
      class="stack-list"
      data-cy="skeleton-loader">
      <div
        v-for="i in 6"
        :key="i"
        class="d-flex flex-column sk-template sk-block pa-4">
        <div class="d-flex">
          <div class="sk-block sk-img sk-dark sk-w-12 sk-h-12 mr-2"/>
          <div class="d-flex flex-column">
            <div class="sk-block sk-title sk-dark sk-w-40"/>
            <div class="sk-block sk-title sk-dark sk-w-16 mt-2"/>
          </div>
        </div>
        <div class="sk-block sk-title sk-dark sk-full-width mt-8"/>
        <div class="sk-block sk-title sk-dark sk-full-width mt-2"/>
        <div class="sk-block sk-title sk-dark sk-w-48 mt-2"/>
        <div class="d-flex mt-16 justify-end">
          <div class="sk-block sk-title sk-dark sk-w-16 mr-2"/>
          <div class="sk-block sk-title sk-dark sk-w-24"/>
        </div>
      </div>
    </div>

    <template v-else>
      <template v-if="!_.isEmpty(unfilteredStacks)">
        <ul
          v-if="!_.isEmpty(filteredStacks)"
          aria-label="Stacks list"
          class="stack-list">
          <!-- TODO: Remove :value workaround once it is no longer required for testing -->
          <v-lazy
            v-for="(stack, index) in paginateStacks(filteredStacks)"
            :key="stack.ref"
            :options="{ rootMargin: '200px' }"
            :value="index < 5 ? true : undefined"
            tag="li">
            <CyWizardServiceCard
              data-cy="service-card"
              :loading="loading"
              :service="stack"
              :show-use-btn="!hideUseBtns"
              :on-click-action="() => { previewedStack = stack }"
              :stack-belongs-to-current-org="isStackInCurrentOrg(stack)"
              @selected="handleWhenSelected"
              @show-visibility-modal="handleVisibilityModalShowing"
              @show-maintainer-modal="handleMaintainerModalShowing"
              @show-import-progress-modal="handleStackImportProgressModalShowing"/>
          </v-lazy>
          <CyDataTablePagination
            :items-length="filteredStacks.length"
            :options.sync="options"
            :items-per-page-options="$static.itemsPerPageOptions"/>
        </ul>
        <v-row
          v-else
          align="center"
          justify="center"
          class="fill-height">
          <v-col class="text-center mt-12">
            <v-icon class="not-found__icon">
              fa-cubes
            </v-icon>

            <h4 class="dark--text mt-6">
              {{ $t('stacks.noResultsFound.title') }}
            </h4>
            <p class="mt-2 mb-6">
              {{ $t('stacks.noResultsFound.subtitle') }}
            </p>
            <CyButton
              theme="primary"
              variant="secondary"
              @click="clearFilters">
              {{ $t('clearFilters') }}
            </CyButton>
          </v-col>
        </v-row>
      </template>
      <template v-else>
        <v-row
          align="center"
          justify="center"
          class="fill-height">
          <v-col class="text-center d-flex flex-column align-center mt-12">
            <v-icon class="not-found__icon">
              fa-cubes
            </v-icon>

            <h3 class="dark--text mt-6">
              {{ $t('stacks.noStacksFound.title') }}
            </h3>
            <p
              class="mt-2 mb-6 width-50 "
              v-html="$sanitizeHtml($t('stacks.noStacksFound.subtitle'))"/>
            <CyButton
              v-has-rights-to="'CreateServiceCatalog'"
              class="create-stack__button"
              data-cy="new-stack-button"
              icon="add"
              @click="openStackModal">
              {{ $t('createNewStack') }}
            </CyButton>
          </v-col>
        </v-row>
      </template>
    </template>

    <CyModal
      v-if="modals.stackDependenciesErrors"
      :header-title="$tc('selectStackModalHeader', modals.stackDependenciesErrors.length)"
      :action-btn-func="() => selectStackAndLoadConfig(selectedStack)"
      :action-btn-text="$t('selectStackModalConfirmBtn')"
      :cancel-btn-text="$t('selectStackModalCancelBtn')"
      :cancel-btn-func="() => $toggle.modals.stackDependenciesErrors(false)"
      modal-type="warning">
      <template slot="default">
        <p v-html="$sanitizeHtml($t('selectStackModalContent', { stackName: selectedStack.name }))"/>
        <span>
          {{ $t('selectStackModalSubContent') }}
        </span>
        <CyTagList
          class="mt-2"
          variant="default"
          :tags="missingDependencyTags"
          small/>
      </template>
    </CyModal>

    <CyInfraImportProgressModal
      v-if="showImportProgressModal"
      has-cancel-btn-visible
      :canonical-or-ref="_.$get(importedStack, 'ref', '')"/>

    <CyModal
      v-if="modals.createStack"
      :action-btn-hidden="!_.isEmpty(catalogRepositories) || !$cycloid.permissions.canDisplay('CreateServiceCatalogSource')"
      :action-btn-text="$t('createCatalogRepository')"
      action-btn-icon="add"
      :action-btn-func="() => $router.push({ name: 'newCatalogRepository' })"
      :cancel-btn-func="() => $toggle.modals.createStack(false)"
      :width="554"
      :header-title="_.isEmpty(catalogRepositories) ? $t('beforeCreatingStacks') : $t('createNewStack')">
      <div
        v-if="!_.isEmpty(catalogRepositories)"
        class="create-stack-modal">
        <p class="mb-8">
          <strong>{{ $t('stacksAre') }}</strong> <span>{{ $t('usingStacks') }}</span>
          <a
            class="cy-link"
            target="_blank"
            :href="$docLinks.infraImport.createAStack"
            rel="noopener noreferrer">
            {{ $t('ReadMore') }}
          </a>
        </p>

        <div class="d-flex justify-space-between">
          <div
            class="creation-method"
            data-cy="design-stack-button"
            @click.stop="$router.push({ name: 'stackFromBlueprint' })">
            <h3>{{ $t('createFromBlueprint') }}</h3>
            <div class="text--default">
              {{ $t('startWithPredefinedBlueprint') }}
            </div>
          </div>
          <div
            class="creation-method ml-6"
            data-cy="import-infra-button"
            @click.stop="$router.push({ name: 'infraImport' })">
            <h3>{{ $t('importInfra') }}</h3>
            <div class="text--default">
              {{ $t('createYourStack') }}
            </div>
          </div>
        </div>

        <p class="mt-8 mb-2">
          {{ $t('createFromCommandLine') }}
        </p>
      </div>

      <div
        v-else
        class="create-stack-modal--no-repos">
        <div v-text="$t('requiredCatalogRepositories')"/>
      </div>
    </CyModal>

    <CyModal
      v-if="modals.changeVisibility"
      :header-title="$t('stacks.visibility.changeVisibility')"
      :loading="saving"
      :action-btn-disabled="!canEditStack"
      action-btn-icon=""
      :action-btn-func="updateVisibility"
      :cancel-btn-text="$t('forms.btnCancel')"
      :cancel-btn-func="$toggle.modals.changeVisibility"
      :action-btn-text="$t('stacks.visibility.set')"
      :close-modal-on-action-click="false"
      :width="555"
      modal-type="info">
      <template #modal-upper-title>
        <CyTag
          class="mb-2"
          variant="default">
          {{ selectedStack.name }}
        </CyTag>
      </template>
      <CyWizardStackVisibilityList :visibility.sync="visibility"/>
    </CyModal>

    <CyModal
      v-if="modals.assignMaintainer"
      :header-title="$t('stacks.maintainer.assignMaintainer')"
      :loading="saving"
      :action-btn-disabled="!canEditStack"
      action-btn-icon=""
      :action-btn-func="updateMaintainer"
      :cancel-btn-text="$t('forms.btnCancel')"
      :cancel-btn-func="$toggle.modals.assignMaintainer"
      :action-btn-text="$t('stacks.maintainer.assignMaintainer')"
      :close-modal-on-action-click="false"
      :width="555"
      modal-type="info">
      <template #modal-upper-title>
        <CyTag
          class="mb-2"
          variant="default">
          {{ selectedStack.name }}
        </CyTag>
      </template>
      <p>{{ $t('stacks.maintainer.description') }}</p>
      <CyWizardStackMaintainerSelector
        :teams="teams"
        :selected.sync="maintainer"/>
    </CyModal>

    <portal
      v-if="previewedStack"
      to="side-panel">
      <div class="side-panel-backdrop"/>
      <v-slide-x-reverse-transition>
        <v-card
          v-click-outside="{
            handler: closePreview,
            include: getClickOutsideExceptions,
          }"
          aria-label="Stack preview"
          role="region"
          class="side-panel">
          <CyStackPreview
            :stack="previewedStack"
            :stack-belongs-to-current-org="isStackInCurrentOrg(previewedStack)"
            @close="closePreview"
            @select="handleWhenSelected(previewedStack)"/>
        </v-card>
      </v-slide-x-reverse-transition>
    </portal>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState, mapMutations } from 'vuex'
import CyBtnToggle from '@/components/btn-toggle.vue'
import CyDataTablePagination from '@/components/data-table/pagination.vue'
import CyFilterTree from '@/components/filter-tree.vue'
import CyStackPreview from '@/components/stack-preview.vue'
import CyTagList from '@/components/tag-list.vue'
import CyWizardServiceCard from '@/components/wizard/service-card.vue'
import CyWizardStackMaintainerSelector from '@/components/wizard/stack-maintainer-selector.vue'
import CyWizardStackVisibilityList from '@/components/wizard/stack-visibility-list.vue'
import { pagination as createAPIPage } from '@/utils/api'
import { gtmStacksEvents } from '@/utils/helpers/analytics'

export default {
  name: 'CyWizardServiceCatalog',
  components: {
    CyInfraImportProgressModal: () => import('@/components/infra-import/progress-modal.vue'),
    CyBtnToggle,
    CyDataTablePagination,
    CyStackPreview,
    CyTagList,
    CyWizardServiceCard,
    CyWizardStackVisibilityList,
    CyWizardStackMaintainerSelector,
    CyFilterTree,
  },
  props: {
    stackRef: {
      type: String,
      default: '',
    },
    hasCreateStackBtnVisible: {
      type: Boolean,
      default: true,
    },
    hideUseBtns: {
      type: Boolean,
      default: false,
    },
    hideHiddenFilter: {
      type: Boolean,
      default: false,
    },
    catalogRepositoryCanonical: {
      type: String,
      default: '',
    },
  },
  data: ({ $route }) => ({
    search: $route.query?.filter || '',
    queryParams: {},
    toggleFilter: '',
    unfilteredStacks: [],
    loading: true,
    firstLoading: true,
    loadingConfig: false,
    saving: false,
    loadingStackName: '',
    selectedStack: null,
    importedStack: null,
    isMenuOpen: false,
    previewedStack: null,
    options: {
      itemsPerPage: 10,
      page: 1,
    },
    newVisibility: '',
    newMaintainer: '',
    modals: {
      createStack: false,
      changeVisibility: false,
      assignMaintainer: false,
      stackDependenciesErrors: false,
    },
    filterOptions: {
      maintainer: [],
      author: [],
      cloudProviders: [],
    },
    importStatus: false,
    selectedFilters: [],
  }),
  computed: {
    ...mapState('organization', {
      stacks: (state) => state.available.stacks,
      catalogRepositories: (state) => state.available.catalogRepositories,
    }),
    ...mapState('organization', {
      teams: (state) => state.available.teams,
    }),
    ...mapState('organization/stack', {
      stackDependenciesErrors: (state) => state.errors.dependencies,
    }),
    ...mapState('organization/infraImport', {
      showImportProgressModal: (state) => state.showImportProgressModal,
    }),
    ...mapGetters('organization/stack', [
      'stack',
      'stackConfig',
    ]),
    $static () {
      return {
        itemsPerPageOptions: [10, 25, 50, 100],
        pagination: {
          page: 1,
          rowsPerPage: 100,
        },
        hiddenItem: [
          {
            icon: 'visibility_off',
            label: this.$t('showHiddenStacks'),
            action: () => { this.queryParams = { catalogVisibility: 'hidden' } },
          },
        ],
        filterKeyMatches: {
          maintainer: 'catalogMaintainer',
          author: 'catalogAuthor',
          provider: 'catalogCloudProviders',
        },
      }
    },
    visibility: {
      get () {
        return this.selectedStack?.visibility
      },
      set (value) {
        this.newVisibility = value
      },
    },
    maintainer: {
      get () {
        return this.selectedStack?.team?.canonical
      },
      set (value) {
        this.newMaintainer = _.find(this.teams, { canonical: value })?.canonical
      },
    },
    filterTree () {
      return {
        maintainer: this.filterOptions.maintainer,
        author: this.filterOptions.author,
        provider: this.filterOptions.cloudProviders,
      }
    },
    selectedFiltersTags () {
      return this.selectedFilters.map((filter) => {
        const [category, value] = filter.split(/\.(.*)/s)
        return [category, value.trim()]
      })
    },
    canEditStack () {
      return this.$cycloid.permissions.canDisplay('UpdateServiceCatalog', this.selectedStack.canonical)
    },
    canGetTeams () {
      return this.$cycloid.permissions.canDisplay('GetTeams')
    },
    visibilityItems () {
      const baseItems = [
        {
          key: this.$t('routes.stacks'),
          text: this.$t('routes.stacks'),
          value: '',
        },
        {
          key: this.$t('stacks.visibility.local'),
          text: this.$t('stacks.visibility.local'),
          value: 'local',
        },
        {
          key: this.$t('stacks.visibility.shared'),
          text: this.$t('stacks.visibility.shared'),
          value: 'shared',
        },
      ]

      const hiddenItem = {
        key: this.$t('stacks.visibility.hidden'),
        text: this.$t('stacks.visibility.hidden'),
        value: 'hidden',
      }

      return this.hideHiddenFilter
        ? baseItems
        : _.concat(baseItems, hiddenItem)
    },
    filteredStacks () {
      return _(this.stacks)
        .sortBy([
          ({ author }) => author !== 'Cycloid',
          'name',
        ]).filter((stack) => this.matchesSearchCriteria(stack)).value()
    },
    missingDependencyTags () {
      if (_.$isEmpty(this.stackDependenciesErrors)) return []
      return this.stackDependenciesErrors.map((dependencyError) => {
        const errorChunks = dependencyError.split(' ')
        const stackCanonical = errorChunks.find((chunk) => chunk.includes(':')) || ''
        const stackCanonicalChunks = stackCanonical.replace(/'/g, '').split(':')
        return {
          label: stackCanonicalChunks[0],
          content: stackCanonicalChunks[1],
        }
      })
    },
    isOnStacksPage () {
      return this.$route.name === 'stacks'
    },
    isOnProjectFromStackPage () {
      return this.$route.name === 'projectFromStack'
    },
  },
  watch: {
    search: 'resetPagination',
    async queryParams (value) {
      if (value?.catalogVisibility === 'hidden' && this.hideHiddenFilter) {
        this.toggleFilter = null
      }
      await this.getStacks()
      this.resetPagination()
    },
    selectedFilters: {
      async handler () {
        await this.getStacks()
        this.resetPagination()
      },
      deep: true,
    },
    stackRef: {
      async handler (newVal) {
        if (_.isEmpty(newVal)) return
        this.loading = true
        await this.GET_STACK({ stackRef: newVal })
        this.loading = false
        if (!_.isEmpty(this.stack)) this.selectStackAndLoadConfig(this.stack)
      },
      immediate: true,
    },
  },
  async mounted () {
    if (this.$cycloid.permissions.canDisplay('GetServiceCatalogSources')) {
      await this.getCatalogRepositories() // needed for scs id on each card
    }

    await Promise.all([this.getTeams(), this.getStacks()])
    this.unfilteredStacks = this.stacks
    this.extractFilterOptions()

    const { 'infra-import': importStatus, canonical } = this.$route.query

    if (importStatus) this.importStatus = true
    if (canonical) this.search = canonical
    this.firstLoading = false
  },
  methods: {
    ...mapActions('organization', [
      'FETCH_AVAILABLE',
    ]),
    ...mapActions('organization/stack', [
      'GET_STACK',
      'GET_STACK_CONFIG',
      'UPDATE_STACK',
      'VALIDATE_STACK_DEPENDENCIES',
    ]),
    ...mapMutations('organization/infraImport', [
      'SHOW_IMPORT_PROGRESS_MODAL',
    ]),
    async handleWhenSelected (stack) {
      this.closePreview()

      if (this.isOnProjectFromStackPage) {
        this.$emit('input', stack)
      }

      this.isOnStacksPage
        ? this.createProjectFromStack(stack.ref)
        : this.handleStackDependencyValidation(stack)
    },
    async handleStackDependencyValidation (stack) {
      if (_.$isEmpty(stack?.dependencies)) return this.selectStackAndLoadConfig(stack)

      this.selectedStack = _.cloneDeep(stack)
      this.$toggle.modals.stackDependenciesErrors(false)

      await this.VALIDATE_STACK_DEPENDENCIES({ stackRef: stack?.ref })

      _.$isEmpty(this.stackDependenciesErrors)
        ? this.selectStackAndLoadConfig(stack)
        : this.$toggle.modals.stackDependenciesErrors(true)
    },
    isStackInCurrentOrg (stack) {
      return stack?.organization_canonical === this.orgCanonical
    },
    handleVisibilityModalShowing (stack) {
      this.selectedStack = stack
      this.$toggle.modals.changeVisibility(true)
    },
    handleMaintainerModalShowing (stack) {
      this.selectedStack = stack
      this.$toggle.modals.assignMaintainer(true)
    },
    handleTagClose (key, value) {
      this.selectedFilters = _.filter(this.selectedFilters, (filter) => filter !== `${key}.${value}`)
    },
    extractFilterOptions () {
      const authors = new Set()
      const providers = new Set()
      const teams = new Set()

      this.stacks.forEach((item) => {
        if (item.author) {
          authors.add(item.author)
        }

        if (item.team) {
          teams.add(item.team.canonical)
        }

        if (item.cloud_providers) {
          item.cloud_providers.forEach((provider) => {
            providers.add(provider.canonical)
          })
        }
      })

      this.filterOptions.author = _.chain(Array.from(authors))
        .sortBy(_.toLower)
        .value()

      this.filterOptions.cloudProviders = _.chain(Array.from(providers))
        .sortBy(_.toLower)
        .value()

      this.filterOptions.maintainer = _.chain(Array.from(teams))
        .sortBy(_.toLower)
        .value()
    },
    parseFilterValues () {
      return _.reduce(this.selectedFilters, (acc, it) => {
        const [category, value] = it.split(/\.(.*)/s)
        const queryParamKey = this.$static.filterKeyMatches[category]
        if (queryParamKey) {
          acc[queryParamKey] = acc[queryParamKey]
            ? `${acc[queryParamKey]},${value}`
            : value
        }
        return acc
      }, {})
    },
    async selectStackAndLoadConfig (stack) {
      const { ref: stackRef, name: stackName } = stack
      if (_.$isEmpty(stackRef)) return
      this.loadingConfig = true
      this.loadingStackName = stackName
      const [success] = await Promise.all([
        this.GET_STACK_CONFIG({ stackRef }),
        this.GET_STACK({ stackRef }),
      ])
      if (success) {
        this.$emit('input', { catalog: stack, config: this.stackConfig })
      }
      this.loadingConfig = false
      this.loadingStackName = ''
    },
    async getStacks () {
      this.loading = true
      const { $static: { pagination: { page, rowsPerPage } }, catalogRepositoryCanonical, importStatus: infraImport } = this
      const pagination = createAPIPage(page, rowsPerPage)
      const {
        catalogAuthor = null,
        catalogMaintainer = null,
        catalogCloudProviders = null,
      } = this.parseFilterValues()

      const { catalogVisibility = null } = this.queryParams

      await this.FETCH_AVAILABLE({
        keyPath: 'stacks',
        extraParams: [
          catalogVisibility,
          infraImport,
          catalogRepositoryCanonical,
          catalogAuthor,
          catalogMaintainer,
          catalogCloudProviders,
          pagination,
        ],
      })
      this.loading = false
    },
    async getCatalogRepositories () {
      this.loading = true
      await this.FETCH_AVAILABLE({ keyPath: 'catalogRepositories' })
      this.loading = false
    },
    async getTeams () {
      if (this.canGetTeams) {
        await this.FETCH_AVAILABLE({ keyPath: 'teams' })
      }
    },
    async updateVisibility () {
      const { newVisibility, selectedStack } = this
      const stack = _.cloneDeep(selectedStack)
      _.set(stack, 'visibility', newVisibility)
      if (selectedStack.team) _.set(stack, 'team_canonical', selectedStack.team.canonical)
      this.$toggle.saving(true)
      await this.UPDATE_STACK({ stack })
      await this.getStacks()
      this.$toggle.saving(false)
      this.closeModals()
    },
    async updateMaintainer () {
      const { newMaintainer, selectedStack } = this
      const stack = _.cloneDeep(selectedStack)
      _.set(stack, 'team_canonical', newMaintainer)
      const team = _.find(this.teams, { canonical: newMaintainer })

      this.$toggle.saving(true)
      await this.UPDATE_STACK({ stack }) && this.$set(selectedStack, 'team', team)

      this.$toggle.saving(false)
      this.closeModals()
    },
    closeModals () {
      for (const key in this.modals) this.modals[key] = false
    },
    handleVisibilityChange (catalogVisibility) {
      this.queryParams = { catalogVisibility }
    },
    filterByInfraImported () {
      this.importStatus = !this.importStatus

      this.queryParams = {
        ...this.queryParams,
        infraImport: this.importStatus || null,
      }
    },
    matchesSearchCriteria (stack) {
      const { search } = this
      if (!search) return true
      const isMatch = (value) => value.toLowerCase().includes(search.toLowerCase())

      const searchableFields = _(['name', 'canonical', 'description', 'author', 'keywords'])
        .flatMap((key) => stack[key] || [])
        .value()

      return searchableFields.some(isMatch)
    },
    paginateStacks (stacks) {
      const { options } = this
      const start = (options.page - 1) * options.itemsPerPage
      const end = start + options.itemsPerPage

      return _.slice(stacks, start, end)
    },
    handleStackImportProgressModalShowing (stack) {
      if (_.$isEmpty(stack)) return

      this.importedStack = stack
      this.SHOW_IMPORT_PROGRESS_MODAL(true)
    },
    createProjectFromStack (stackRef) {
      this.$router.push({ name: 'projectFromStack', query: { selectedStackRef: stackRef } })
    },
    clearFilters () {
      this.search = ''
      this.queryParams = {}
      this.toggleFilter = ''
      this.selectedFilters = []
      this.importStatus = false
      this.resetPagination()
    },
    resetPagination () {
      this.options.page = 1
    },
    openStackModal () {
      this.$toggle.modals.createStack(true)
      this.$gtm.trackEvent(gtmStacksEvents.stacksAllStacksCreateANewStack)
    },
    getClickOutsideExceptions () {
      const selectors = [
        '.main-nav a',
        '.main-nav button',
        '.dev-locale-switcher__options',
        '.dev-layer',
        '.v-menu__content',
      ]
      return Array.from(document.querySelectorAll(selectors.join(', ')))
    },
    closePreview () {
      this.previewedStack = null
    },
  },
  i18n: {
    messages: {
      en: {
        beforeCreatingStacks: 'Before creating stacks',
        clearFilters: 'Clear filters',
        createCatalogRepository: 'Create catalog repository',
        createFromCommandLine: 'You can also create stacks from the command line by pushing code to a catalog repository.',
        createFromBlueprint: 'Create from blueprint',
        createNewStack: 'Create a new stack',
        createYourStack: 'Create a stack based on your existing infrastructure',
        designYourStack: 'Design your stack',
        dragAndDropResources: 'Drag and drop resources within a visual interface',
        filter: 'Filter',
        followGuideSteps: 'Follow our step-by-step guide to manually create a @:stack',
        importInfra: 'Import your infrastructure',
        loadingServiceConfig: `Loading {service}'s configuration`,
        requiredCatalogRepositories: 'Having a catalog repository is required before you can create stacks. Catalog repositories are git repositories used to stored your stacks.',
        search: 'Search by name, author, keyword or cloud provider',
        selectStackModalCancelBtn: 'Go back to stack select step',
        selectStackModalConfirmBtn: 'Continue anyway',
        selectStackModalContent: 'The following stacks are required to run <strong>{stackName}</strong> but are currently missing.',
        selectStackModalHeader: 'Missing dependency | Missing dependencies',
        selectStackModalSubContent: 'Your project may not fully work without them.',
        showHiddenStacks: 'Show hidden stacks',
        stacksAre: 'Stacks are versatile and reusable application templates.',
        startWithPredefinedBlueprint: 'Start with a pre-defined blueprint to get started quickly.',
        usingStacks: 'Using stacks encourages standardization across your organization, enabling people to deploy new application instances with confidence.',
      },
      es: {
        beforeCreatingStacks: 'Antes de crear stacks',
        clearFilters: 'Borrar filtros',
        createCatalogRepository: 'Crear repositorio de catálogo',
        createFromCommandLine: 'También puedes crear stacks desde la línea de comandos insertando código en un repositorio de catálogo.',
        createFromBlueprint: 'Crear desde plantilla',
        createNewStack: 'Crear una nueva stack',
        createYourStack: 'Cree una stack basada en su infraestructura existente',
        designYourStack: 'Diseña tu stack',
        dragAndDropResources: 'Arrastra y suelta recursos dentro de una interfaz visual',
        filter: 'Filtrar',
        followGuideSteps: 'Siga nuestra guía paso a paso para crear manualmente un @:stack',
        importInfra: 'Importa tu infraestructura',
        loadingServiceConfig: 'Cargando la configuración de {service}',
        requiredCatalogRepositories: 'Se requiere tener un repositorio de catálogo antes de poder crear stacks. Los repositorios de catálogo son repositorios de git que se utilizan para almacenar sus stacks.',
        search: 'Buscar por nombre, autor, palabra clave o cloud provider',
        selectStackModalCancelBtn: 'Volver al paso de selección de lo stack',
        selectStackModalConfirmBtn: 'De todas maneras, continúe',
        selectStackModalContent: 'Los siguientes stacks son necesarios para ejecutar <strong> {stackName} </strong>, pero faltan actualmente.',
        selectStackModalHeader: 'Falta de dependencia | Dependencias faltantes',
        selectStackModalSubContent: 'Es posible que su proyecto no funcione completamente sin ellos.',
        showHiddenStacks: 'Mostrar stacks ocultos',
        stacksAre: 'Las stacks son plantillas de aplicaciones versátiles y reutilizables.',
        startWithPredefinedBlueprint: 'Comience con una plantilla predefinida para comenzar rápidamente.',
        usingStacks: 'El uso de stacks fomenta la estandarización en toda su organización, lo que permite a las personas implementar nuevas instancias de aplicaciones con confianza.',
      },
      fr: {
        beforeCreatingStacks: 'Avant de créer des stacks',
        clearFilters: 'Effacer les filtres',
        createCatalogRepository: 'Créer un dépot de catalogues',
        createFromCommandLine: 'Vous pouvez également créer des stacks à partir de la ligne de commande en envoyant du code dans une source de catalogue.',
        createFromBlueprint: `Créer à partir d'un modèle`,
        createNewStack: 'Créer une nouvelle stack',
        createYourStack: 'Créez une stack basée sur votre infrastructure existante',
        designYourStack: 'Concevez votre stack',
        dragAndDropResources: 'Faites glisser et déposez des ressources dans une interface visuelle',
        filter: 'Filtre',
        followGuideSteps: 'Suivez notre guide étape par étape pour créer manuellement une @:stack',
        importInfra: 'Importez votre infrastructure',
        loadingServiceConfig: 'Chargement de la configuration de {service}',
        requiredCatalogRepositories: 'Avoir un dépot de catalogues est nécessaire avant de pouvoir créer des stacks. Les dépots de catalogues sont des référentiels git utilisés pour stocker vos stacks.',
        search: 'Chercher par nom, auteur, mot-clé ou cloud provider',
        selectStackModalCancelBtn: `Revenir à l'étape de sélection de la stack`,
        selectStackModalConfirmBtn: 'Continuer quand même',
        selectStackModalContent: 'Les stacks suivantes sont nécessaires pour exécuter <strong>{stackName}</strong> mais sont actuellement manquantes.',
        selectStackModalHeader: 'Dépendance manquante | Dépendances manquantes',
        selectStackModalSubContent: 'Votre projet peut ne pas fonctionner complètement sans elles.',
        showHiddenStacks: 'Afficher les stacks cachés',
        stacksAre: `Les stacks sont des modèles d'application polyvalents et réutilisables.`,
        startWithPredefinedBlueprint: 'Commencez avec un modèle prédéfini pour démarrer rapidement.',
        usingStacks: `L'utilisation de stacks encourage la standardisation au sein de votre organisation, permettant aux utilisateurs de déployer de nouvelles instances d'application en toute confiance.`,
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.cy-search-field {
  height: $spacer-8;
  border: 1px solid cy-get-color("grey");
  border-radius: 4px;
  background: cy-get-color("white");

  ::v-deep .v-input {
    &__prepend-outer {
      margin-left: 12px;
    }

    &__append-inner {
      margin-right: 4px;
    }

    .v-icon {
      font-size: 20px;
    }

    &__slot {
      &::before {
        display: none;
      }

      &::after {
        display: none;
      }
    }
  }
}

.not-found {
  &__icon {
    padding: 11px;
    border: 1px solid cy-get-color("white");
    border-radius: 8px;
    background-color: cy-get-color("primary", "light-4");
    color: cy-get-color("primary", "light-2");
  }
}

.service-catalog-container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: $spacer-4;

    &__search-bar {
      display: flex;
      gap: 0.5em 1em;
      flex-wrap: wrap;
      align-items: center;
    }

    &__actions {
      display: flex;
      align-items: center;

      .create-stack__button {
        height: $spacer-8 !important;
        margin-left: $spacer;
        padding-right: 19px;
        padding-left: 22px;
      }
    }

    @media (width <= 985px) {
      flex-direction: column;

      &__actions {
        align-self: flex-end;
      }
    }
  }

  .tags {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5em 1em;
    margin-bottom: $spacer-4;
  }
}

.catalog-types-select {
  margin-top: 11px;
}

.catalog-types-select .v-input__control .v-input__slot {
  margin-bottom: 0;
}

.v-list-item__avatar {
  .svg-inline--fa {
    width: 24px;
    height: 24px;
  }
}

.creation-method {
  box-sizing: border-box;
  width: 233px;
  padding: 12px;
  border: 1px solid cy-get-color("grey");
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    border: 1px solid cy-get-color("accent");
    box-shadow: 1px 1px 1px 0 cy-get-color("accent", $alpha: 0.2);
  }

  &:active,
  &:focus {
    border: 1px solid cy-get-color("accent");
    box-shadow: 1px 1px 1px 0 cy-get-color("accent", $alpha: 0.2);
  }

  .text--default {
    margin-top: 8px;
    color: cy-get-color("primary");
    font-size: $font-size-sm;
  }
}

.stack-list {
  display: grid;
  gap: 16px;
  padding-left: 0;
  list-style: none;
}

.side-panel {
  $offset: 8px;

  display: flex;
  position: fixed;
  z-index: 110;
  top: $offset;
  right: $offset;
  flex-direction: column;
  width: 830px;
  height: calc(100% - #{$offset} * 2);

  &-backdrop {
    position: fixed;
    z-index: 108;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0 0 0 / 0.5);
  }
}

::v-deep strong {
  white-space: normal !important;
}

.cy-modal {
  &__header {
    .tag {
      margin-right: auto;

      b {
        font-weight: $font-weight-default !important;
      }
    }
  }
}
</style>
